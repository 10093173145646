<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('秒杀库存.时间轴id')" prop="seckillTimeId">
                <a-select mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('秒杀库存.时间轴id')" style="width: 100%"
                          v-model="queryParam.seckillTimeId" allowClear>
                  <a-select-option v-for="seckillTime in seckillList" :value="seckillTime.id">
                    {{ seckillTime.startTime }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('秒杀库存.状态')" prop="status">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('秒杀库存.状态')" style="width: 100%" v-model="queryParam.status"
                          allowClear>
                  <a-select-option v-for="(item, index) in customDict.SECKILLENUM" :value="item.type" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('秒杀库存.商品名称')" prop="goodsName">
                <a-input v-model="queryParam.goodsName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('秒杀库存.商品名称')"
                         allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>{{ $t('通用.按钮.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon
                  type="redo"/>{{ $t('通用.按钮.重置') }}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['zb:seckill:add']">
          <a-icon type="plus"/>
          {{ $t('通用.按钮.新增') }}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
<!--        <span slot="checkStatus" slot-scope="text, record">
            <a-select style="width: 50%" v-model="record.checkStatus">
              <a-select-option v-for="(item, index) in customDict.SECKILLENUM" :value="item.type"
                               :key="index">
                        {{ item.name }}
              </a-select-option>
            </a-select>
          </span>-->
        <span slot="checkStatus" slot-scope="text, record">
           <custom-dict-tag :options="customDict.SECKILLENUM" :value="record.checkStatus"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['zb:seckill:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['zb:seckill:edit']">
             <a-icon type="edit"/>{{ $t('通用.按钮.修改') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['zb:seckill:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['zb:seckill:remove']">
            <a-icon type="delete"/>{{ $t('通用.按钮.删除') }}
          </a>
          <a-popconfirm title="确定要审核通过吗？" ok-text="确定" cancel-text="取消" @confirm="pass(record)" >
          <a href="javascript:void(0)" v-if="(record.checkStatus === 1) && record.adminZbFlag ">审核通过</a>
          </a-popconfirm>
          <a href="javascript:void(0)" v-if="(record.checkStatus === 1)&& record.adminZbFlag" class="text-red margin-left-sm"
             @click="refuseModal(record)" v-hasPermi="['shop:shopApply:audit']">审核拒绝</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <a-modal title="审核" :visible="visible" :confirm-loading="confirmLoading" @ok="refuse" @cancel="visible = false">
        <a-input v-model="auditOpinion" placeholder="填写审核意见" type="textarea" />
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageSeckill, listSeckill, delSeckill,updateSeckillaudit} from '@/api/zb/seckill'
import {pageSeckillTime, listSeckillTime, delSeckillTime} from '@/api/seckill/seckillTime'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {allLabel} from "@/api/goods/label";
import {checkApplication} from "@/api/shop/shopApply";

export default {
  name: 'Seckill',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      visible: false,
      auditOpinion: '',
      confirmLoading: false,
      seckillList: [],//时间轴List
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        seckillTimeId: undefined,
        goodsId: undefined,
        goodsName: undefined,
        seckillPrice: undefined,
        goodsPrice: undefined,
        seckillNum: undefined,
        goodsPicture: undefined,
        stock: undefined,
        shopId: undefined,
        shopName: undefined,
        checkStatus: undefined,
        complete: undefined,
        refuseReason: undefined,
        startTime: undefined,
        endTime: undefined,
        maxBuyCount: undefined,
        seckillList: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: "主键",
        //   dataIndex: 'id',
        //   width:300,
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('秒杀库存.商品名称'),
          dataIndex: 'goodsName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.秒杀开始时间'),
          dataIndex: 'startTime',
          // scopedSlots: {customRender: 'startTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.秒杀结束时间'),
          dataIndex: 'endTime',
          // scopedSlots: {customRender: 'endTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.已经秒杀数量'),
          dataIndex: 'seckillNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.商品现价'),
          dataIndex: 'goodsPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.秒杀价格'),
          dataIndex: 'seckillPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.店铺名字'),
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.拒绝原因'),
          dataIndex: 'refuseReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('秒杀库存.状态'),
          dataIndex: 'checkStatus',
          ellipsis: true,
          scopedSlots: {customRender: 'checkStatus'},
          align: 'center'
        },

        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getSecKillTimeListValue();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    /** 查询秒杀库存列表 */
    getList() {
      this.loading = true
      pageSeckill(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    pass(info, onOff) {
      updateSeckillaudit({
        id: info.id,
        checkStatus: 3,
      }).then(response => {
        if (response.success) {
          this.$message.success('操作成功');
          this.getList();
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    refuseModal(info) {
      this.visible = true
      this.curInfo = info
    },
    refuse(info, onOff) {
      this.confirmLoading = true
      updateSeckillaudit({
        id: this.curInfo.id,
        checkStatus: 2,
        refuseReason: this.auditOpinion
      }).then(response => {
        this.visible = false
        this.confirmLoading = false
        if (response.success) {
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.visible = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        seckillTimeId: undefined,
        goodsId: undefined,
        goodsName: undefined,
        seckillList: undefined,
        seckillPrice: undefined,
        goodsPrice: undefined,
        seckillNum: undefined,
        goodsPicture: undefined,
        stock: undefined,
        shopId: undefined,
        shopName: undefined,
        checkStatus: undefined,
        complete: undefined,
        refuseReason: undefined,
        startTime: undefined,
        endTime: undefined,
        maxBuyCount: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField === 'createTime') {
          this.queryParam.sortField = 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delSeckill(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    getSecKillTimeListValue() {
      var that = this;
      listSeckillTime().then((response) => {
        this.seckillList = response.data;
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('zb/seckill/export',
            ...that.queryParam
            , `秒杀库存_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
